import '@hotwired/turbo-rails'
import 'arrive'
import Venobox from 'venobox'
import * as bootstrap from 'bootstrap'

// sharer.js
import 'sharer.js'
document.addEventListener('turbo:load', function(event) { Sharer.init() })

// plausible.io + Turbo
document.addEventListener('turbo:load', function() {
  try { plausible('pageview') }
  catch(err) { console.log('Plausible: not available.') }
})

// make all external links open in new tab
document.arrive('.richtext', { existing: true }, function(el) {
  const selector = "a[href^='http']:not([href^='http://www.feet-europe.org']):not([href^='http://feet-europe.org']):not([href^='https://www.feet-europe.org']):not([href^='https://feet-europe.org'])"
  el.querySelectorAll(selector).forEach(function (e) {e.setAttribute("target", "_blank") })
})

// venobox
document.addEventListener('turbo:load', function() {
  new Venobox({
    selector: '.venobox',
    share: []
  })
})

// CSS Styles
import '~/stylesheets/application.scss'
